import React from "react";
import { Navigate } from "react-router-dom";

interface IPrivateRoute {
  isValid: boolean;
  failPath: string;
  children: JSX.Element;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
  children,
  isValid,
  failPath,
}) => {
  if (!isValid) {
    return <Navigate to={failPath} replace />;
  }

  return children;
};
export default PrivateRoute;
