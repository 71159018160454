import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginScope } from "../lib/msal";

const HomeView: React.FC = () => {
  const isLoggedIn = useIsAuthenticated();
  const { instance: msalInstance } = useMsal();

  const doLogin = async () => {
    const res = await msalInstance.loginPopup(loginScope);
    msalInstance.setActiveAccount(res.account);
  };

  const doLogout = async () => {
    await msalInstance.logoutPopup();
    msalInstance.setActiveAccount(null);
  };

  const user = msalInstance.getActiveAccount()?.name;

  return (
    <div>
      {isLoggedIn ? (
        <button onClick={doLogout}>Do logout</button>
      ) : (
        <button onClick={doLogin}>Do login</button>
      )}
      {user && <p>Hello {user}</p>}
    </div>
  );
};

export default HomeView;
