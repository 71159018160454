import {
  PublicClientApplication,
  Configuration,
  SilentRequest,
  PopupRequest,
  AuthenticationResult,
} from "@azure/msal-browser";

function getMsalConfig(): Configuration {
  const tenantId = "8a22e557-6496-4051-b3a6-41f6657ab60c";
  const clientId = "7e83841f-d828-4ee8-ab69-7449517952d4";
  return {
    auth: {
      clientId,
      authority: `https://login.microsoftonline.com/${tenantId}/`,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };
}

export function getMsalInstance(): PublicClientApplication {
  return new PublicClientApplication(getMsalConfig());
}

export const loginScope: SilentRequest & PopupRequest = {
  scopes: ["openid", "profile", "email" /* , 'User.ReadBasic.All' */],
  // extraScopesToConsent: ['Group.ReadWrite.All', 'User.ReadWrite.All', 'GroupMember.Read.All', 'Directory.Read.All', 'DeviceManagementServiceConfig.Read.All'],
};

let authPending: any | undefined;
let authPendingPromise: Promise<void> | undefined;

/**
 * Tällä haetaan token requestin mukaisesti (tässä suojaus että ei voi tapahtua useampia samanaikaisia)
 */
export const getAuthReponse = async (
  request: PopupRequest | SilentRequest
): Promise<AuthenticationResult> => {
  if (!authPendingPromise) {
    authPendingPromise = new Promise((resolve) => (authPending = resolve));
  } else {
    await authPendingPromise;
  }
  const msalInstance = getMsalInstance();
  let response: AuthenticationResult | undefined;
  try {
    response = await msalInstance.acquireTokenSilent(request);
  } catch (err) {
    msalInstance.setActiveAccount(null); // this should trigger a login view
    throw err;
  }
  // resolve pendings
  if (authPending) {
    authPending();
    authPending = undefined;
    authPendingPromise = undefined;
  }
  if (!response) {
    throw new Error("no auth response");
  }
  return response;
};

/**
 // esimerkki jos on API:lle on oma App Registraion (eli saadaan oma access token API:lle)
export const backendRequest = async (forceRefresh = false): SilentRequest & PopupRequest => {
  return {
    scopes: [`api://${API CLIENT ID TÄHÄN}/{JOKU API SCOPE TAI EHKÄ .default TOIMII KANS}`],
    forceRefresh,
  };
};

eli 
const ownApiToken = await getAuthReponse(backendRequest());
josta
ownApiToken.accessToken // on token
 */
