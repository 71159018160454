import React from "react";
import { useNavigate } from "react-router-dom";

const Menu: React.FC = () => {
  const nav = useNavigate();
  return (
    <nav>
      <ul style={{ display: "inline-block" }}>
        <button onClick={() => nav("/")}>Home</button>
      </ul>
      <ul style={{ display: "inline-block" }}>
        <button onClick={() => nav("/secret")}>Secret</button>
      </ul>
    </nav>
  );
};

export default Menu;
