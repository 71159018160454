import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import HomeView from "./views/HomeView";
import PrivateRoute from "./components/PrivateRoute";
import SecretView from "./views/SecretView";
import { useIsAuthenticated } from "@azure/msal-react";
import Menu from "./components/Menu";

const App: React.FC = () => {
  const isLoggedIn = useIsAuthenticated();
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Menu />
          <Routes>
            <Route index element={<HomeView />} />
            <Route
              path="secret"
              element={
                <PrivateRoute isValid={isLoggedIn} failPath="/">
                  <SecretView />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </header>
    </div>
  );
};

export default App;
